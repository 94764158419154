import React, { useState, Fragment, useEffect, useRef } from "react";
import clsx from "clsx";
import Header from "../Header/Header";
import "./HeaderSidebarWrapper.scss";
import icon_to_close_sidebar from "../../assets/images/icon_to_close_sidebar.svg";
import icon_to_open_sidebar from "../../assets/images/open-side-menu.svg";
import walleticon from "../../assets/walleticon.png";
import AssessmentIcon from "@material-ui/icons/Assessment";
import smallLogo from "../../assets/images/inner-short-logo.jpeg";
import largeLogo from "../../assets/images/largeLogo.png";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsActive";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import TimelineIcon from "@material-ui/icons/Timeline";
import PieChartIcon from "@material-ui/icons/PieChart";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LanguageIcon from "@material-ui/icons/Language";
import ListIcon from "@material-ui/icons/List";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import MapIcon from "@material-ui/icons/Map";
import { Redirect, useHistory, useLocation } from "react-router";
import headerDropdownService from "../../services/headerDropdown.service";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import useLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { extensionSubject } from "../../containers/Extensions/ExtensionActionButton";
import { useAppContext } from "../../AppContext/App.context";
import { extensionList } from "../../constants/extensionList";
import { themeColor } from "../../constants/themeColor";
import AnalyticsIcon from "../../assets/images/header/Analytics.svg";
import AutoAllocationIcon from "../../assets/images/header/AutoAllocation.png";
import RolesIcon from "../../assets/images/header/Roles.png";
import CustomerIcon from "../../assets/images/header/Customer.svg";
import HelpCenterIcon from "../../assets/images/header/HelpCenter.svg";
import JobListIcon from "../../assets/images/header/JobList.svg";
import TagsManagerIcon from "../../assets/images/header/Tagsmanager.svg";
import BroadCastIcon from "../../assets/images/header/BroadCast.svg";
import AgentReferral from "../../assets/images/header/AgentReferral.svg";
import PaymentIcon from "@material-ui/icons/Payment";
import SchedulerIcon from "../../assets/images/header/Scheduler.svg";
import redirect from "../../utils/Redirect";
import useApiService from "../../services/api.service";
import Face from "@material-ui/icons/Face";
import ChatEndpoints from "../../containers/Chat/Dashboard/Chat.Endpoints";
import { chatSocketSubject } from "../../utils/chatSocket";
import ErrorIcon from "@material-ui/icons/Error";
import red from "@material-ui/core/colors/red";
import AgentWalletReport from "../../containers/Extensions/AgentWallet/AgentWalletReport";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "0 0 5px 0 #e5e9f2",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  fakeMenuButton: {
    "&:hover, &:focus": {
      background: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    color: themeColor.whiteColor,
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "var(--sidebar-theme)",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: "var(--sidebar-theme)",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  largeLogo: {
    maxWidth: "200px",
    maxHeight: "50px",
    padding: "10px",
    width: "calc(100% - 32px)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selected: {
    // height: "70px",
    "&.Mui-selected": {
      color: themeColor.whiteColor,
      borderImage: "var(--active-sidebar-border-image)",
      backgroundImage: "var(--active-sidebar-background-image)",
      width: "calc(100% - 10px)",
      padding: "10px",
      borderRadius: "50px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      borderLeft: "4px solid",
      "& svg": {
        color: themeColor.whiteColor,
      },
      // "&:hover, &:focus": {
      //   backgroundImage:
      //     "linear-gradient(to left, rgba(65,173,73,1) 0%, rgba(0,0,0,1) 101%)",
      //   color: "#fff",
      // },
    },
  },
  floatingIcon: {
    position: "absolute",
    bottom: "25px",
  },
  list: {
    height: "calc(100vh - 160px)",
    color: themeColor.whiteColor,
    "& .MuiListItemIcon-root": {
      minWidth: "44px",
    },
  },
  divider: {
    transition: "all .32s",
    outline: "none",
    background: "#3e3e3e",
    height: "1px",
    margin: "5px 0",
    width: "205px",
    marginLeft: "0px",
    padding: "0 !important",
    marginBottom: "10px",
  },
  subCategoryIcon: {
    marginLeft: "24px",
  },
  subCategoryList: {
    background: "var(--sidebar-theme)",
  },
  smallLogo: {
    marginLeft: "-10px",
    maxWidth: "35px",
    maxHeight: "35px",
  },
}));

const HeaderSidebarWrapper = (props: any) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation(["Sidebar", "Wallet"]);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [settingsListOpen, setSettingsListOpen] = useState(false);
  const [extensionsListOpen, setExtensionsListOpen] = useState(false);
  const [analyticsListOpen, setAnalyticsListOpen] = useState(false);
  const [showRecurring, setShowRucurring]: any = useState(true);
  const [showStripeHistory, setShowStripeHistory]: any = useState(false);
  const [showRouteAnalytics, setShowRouteAnalytics]: any = useState(false);
  const [merchantAnalytics, setMerchantAnalytics] = useState<boolean>(false);
  const [tripAnalytics, setTripAnalytics] = useState<boolean>(false);
  const [eatHappyNowReport, setEatHappyNowReport] = useState<boolean>(false);
  const [showCustomerApp, setShowCustomerApp]: any = useState(false);
  const [showCustomReport, setShowCustomReport]: any = useState(false);
  const [showAgentReferral, setShowAgentReferral] = useState<any>(false);
  const [showIdentityVerification, setShowIdentityVerification] = useState(
    false
  );
  const [showAgentWallet, setShowAgentWallet]: any = useState(false);
  const [
    handleExtensionsVisibilty,
    setHandleExtensionsVisibilty,
  ]: any = useState({
    recurring: false,
    bookingForm: false,
    stripeHistory: false,
    routeAnalytics: false,
    merchantAnalytics: false,
    tripAnalytics: false,
    customerApp: false,
    broadcast: false,
    agentReferral: false,
    scheduledPayments: false,
  });
  const arrayOfLocation = [
    "app.truckiee.com",
    "app.cupboardwagon.com",
    "whsdeliverytracker.trolley.com.kw",
  ];
  const ApiService = useApiService();
  const location = useLocation();
  const history = useHistory();
  const [appData]: any = useAppContext();
  const [userIcons, setUserIcons]: any = useState();
  const [isAdmin, setIsAdmin]: any = useState(true);
  const [showBookingForm, setShowBookingForm]: any = useState(false);
  const [showScheduledPayment, setShowScheduledPayment]: any = useState(false);
  const [customerSubscription, setCustomerSubscription]: any = useState(false);
  const [agentIncentive, setAgentIncentive]: any = useState(false);
  const [customerPromo, setCustomerPromo]: any = useState(false);
  const [UnreadMsg, setUnreadMsg] = useState(false);
  let selectedAndUnreadMsges: any = useRef({});
  const [socketConvId, setSocketConvId]: any = useState("");
  const [workflowType, setworkflowType] = useState(
    appData?.loginData?.workflow_type
  );

  const [Permission, setPermission]: any = useState(
    useLocalStorage.getItem("Permission")
  );
  let APIKey: any = true;
  let PermissionsArr: any = Permission ? Object.values(Permission) : [];
  // if (PermissionsArr.length) {
  //   PermissionsArr.map((val: any) => {
  //     if (!val) {
  //       APIKey = false;
  //       return;
  //     }
  //   });
  // }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const closeAnotherExpandedItems = (newOpenedItem: string) => {
    switch (newOpenedItem) {
      case "analytics":
        setSettingsListOpen(false);
        setExtensionsListOpen(false);
        break;
      case "extensionsList":
        setSettingsListOpen(false);
        setAnalyticsListOpen(false);
        break;
      case "settingsList":
        setAnalyticsListOpen(false);
        setExtensionsListOpen(false);
        break;
    }
  };
  const resetHeaderTeam = () => {
    const data = {
      type: "resetTeamListForHeader",
      data: { teamId: "" },
    };
    headerDropdownService.emit(data);
  };

  const goToHome = () => {};
  const openCretaeTaskFromSidebar = () => {
    const dropdownData = {
      type: "clickedOnCreateTask",
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
  };
  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);

  const eventFromService = (eventData: any) => {
    switch (eventData.type) {
      case "workflowChange":
        setworkflowType(eventData.value);
        break;
      case "permissionData":
        {
          setPermissionData(eventData.data);
          getActivatedExtensions();
        }
        break;
      case "unreadMsgCount":
        handleRedDot(eventData?.data);
        break;
      case "SelectedAndUnreadMsges":
        selectedAndUnreadMsges.current.value = eventData?.data;
        break;
      default:
        break;
    }
  };
  const setPermissionData = (data: any) => {
    setPermission(data);
  };
  const handleRedDot = (toggle: any) => {
    if (toggle) {
      setUnreadMsg(true);
    } else {
      setUnreadMsg(false);
    }
  };
  const handleSocketResponse = (eventData: any) => {
    switch (eventData.type) {
      case "messageToClient":
        setSocketConvId(eventData?.res?.conversation_id);
        if (window.location.href.includes("/chat")) {
          if (Object.keys(selectedAndUnreadMsges.current.value).length === 0) {
            handleRedDot(true);
          } else {
            handleSelectedChatRedDot(eventData?.res?.conversation_id);
          }
        } else {
          selectedAndUnreadMsges.current.value = {};
          handleRedDot(true);
        }
        break;
      case "new_conversation":
        setSocketConvId(eventData?.res?.conversation_id);
        handleSelectedChatRedDot(eventData?.res?.conversation_id);
        break;
      default:
        break;
    }
  };
  const handleSelectedChatRedDot = (conversationId?: any) => {
    if (
      (selectedAndUnreadMsges.current.value?.item == socketConvId ||
        selectedAndUnreadMsges.current.value?.item == conversationId) &&
      selectedAndUnreadMsges.current.value?.unreadMsgIds?.length === 0
    ) {
      handleRedDot(false);
    } else {
      handleRedDot(true);
    }
  };

  useEffect(() => {
    let socketSubscription: any = chatSocketSubject.subscribe((res: any) => {
      handleSocketResponse(res);
    });
    return () => {
      socketSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setWhiteLabels();
    fetchChatConversationStatus();

    getActivatedExtensions();
    let extensionSubscription: any = extensionSubject.subscribe((res: any) => {
      if (
        res.action === "activated" &&
        res.id === extensionList.bookingForm.id
      ) {
        setShowBookingForm(true);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.customReport.id
      ) {
        // setHandleExtensionsVisibilty({
        //   ...handleExtensionsVisibilty,
        //   customReport: false,
        // });
        setShowCustomReport(false);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.customReport.id
      ) {
        // setHandleExtensionsVisibilty({
        //   ...handleExtensionsVisibilty,
        //   customReport: true,
        // });
        setShowCustomReport(true);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.bookingForm.id
      ) {
        setShowBookingForm(false);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.recurring.id
      ) {
        setShowRucurring(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.identityVerification.id
      ) {
        setShowIdentityVerification(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.agentWallet.id
      ) {
        setShowAgentWallet(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.scheduledPayments.id
      ) {
        setShowScheduledPayment(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.customerPromos.id
      ) {
        setCustomerPromo(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.customerSubscription.id
      ) {
        setCustomerSubscription(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.agentIncentive.id
      ) {
        setAgentIncentive(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.merchantAnalytics.id
      ) {
        setMerchantAnalytics(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.tripAnalytics.id
      ) {
        setTripAnalytics(true);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.eatHappyNowCustomReport.id
      ) {
        setEatHappyNowReport(true);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.scheduledPayments.id
      ) {
        setShowScheduledPayment(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.customerSubscription.id
      ) {
        setCustomerSubscription(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.agentIncentive.id
      ) {
        setAgentIncentive(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.customerPromos.id
      ) {
        setCustomerPromo(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.eatHappyNowCustomReport.id
      ) {
        setEatHappyNowReport(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.merchantAnalytics.id
      ) {
        setMerchantAnalytics(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.tripAnalytics.id
      ) {
        setTripAnalytics(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.recurring.id
      ) {
        setShowRucurring(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.identityVerification.id
      ) {
        setShowIdentityVerification(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.agentWallet.id
      ) {
        setShowAgentWallet(false);
      } else if (
        res.action === "activated" &&
        res.id === extensionList.stripeConnect.id
      ) {
        setShowStripeHistory(true);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.stripeConnect.id
      ) {
        setShowStripeHistory(false);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.agentReferral.id
      ) {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          agentReferral: false,
        });
      } else if (
        res.action === "activated" &&
        res.id === extensionList.agentReferral.id
      ) {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          agentReferral: true,
        });
      } else if (
        res.action === "activated" &&
        res.id === extensionList.broadcast.id
      ) {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          broadcast: true,
        });
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.broadcast.id
      ) {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          broadcast: false,
        });
      }
    });

    checkAdmin();
    return () => {
      extensionSubscription.unsubscribe();
    };
  }, []);

  const checkAdmin = () => {
    if (
      appData.loginData.user_id === appData.loginData.admin_id ||
      appData.loginData.role_id === 1
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const getFavTextEl = () => {
    return document.getElementById("favtitle");
  };
  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };
  const setWhiteLabels = async () => {
    if (appData.loginData) {
      setUserIcons({
        inner_logo: appData.loginData.inner_logo,
        outer_logo: appData.loginData.outer_logo,
        favicon: appData.loginData.favicon,
      });
    }
    let favName: any = await getFavTextEl();
    favName.innerHTML = appData.loginData.company_name || "Network On";
    let favIcon: any = await getFaviconEl();
    favIcon.href =
      appData.loginData.favicon !== null
        ? appData.loginData.favicon
        : window.location.origin + "/favicon1.svg";
  };

  const getActivatedExtensions = () => {
    if (localStorage.getItem("activatedExtension") !== null) {
      let data: any = localStorage.getItem(`activatedExtension`);
      let activatedExtension: any = JSON.parse(data);
      let tempVisibleExtensionsData = { ...handleExtensionsVisibilty };
      if (activatedExtension.includes(extensionList.recurring.id)) {
        setShowRucurring(true);
        tempVisibleExtensionsData.recurring = true;
      } else {
        setShowRucurring(false);
        tempVisibleExtensionsData.recurring = false;
      }
      if (activatedExtension.includes(extensionList.routeAnalytics.id)) {
        setShowRouteAnalytics(true);
        tempVisibleExtensionsData.routeAnalytics = true;
      }
      if (activatedExtension.includes(extensionList.merchantAnalytics.id)) {
        setMerchantAnalytics(true);
      }
      if (activatedExtension.includes(extensionList.tripAnalytics.id)) {
        setTripAnalytics(true);
      }
      if (
        activatedExtension.includes(extensionList.eatHappyNowCustomReport.id)
      ) {
        setEatHappyNowReport(true);
      } else {
        setEatHappyNowReport(false);
      }
      if (activatedExtension.includes(extensionList.bookingForm.id)) {
        setShowBookingForm(true);
      } else {
        setShowBookingForm(false);
      }
      if (activatedExtension.includes(extensionList.scheduledPayments.id)) {
        setShowScheduledPayment(true);
      } else {
        setShowScheduledPayment(false);
      }
      if (activatedExtension.includes(extensionList.customerSubscription.id)) {
        setCustomerSubscription(true);
      } else {
        setCustomerSubscription(false);
      }
      if (activatedExtension.includes(extensionList.agentIncentive.id)) {
        setAgentIncentive(true);
      } else {
        setAgentIncentive(false);
      }
      if (activatedExtension.includes(extensionList.customerPromos.id)) {
        setCustomerPromo(true);
      } else {
        setCustomerPromo(false);
      }
      if (activatedExtension.includes(extensionList.customReport.id)) {
        // setHandleExtensionsVisibilty({
        //   ...handleExtensionsVisibilty,
        //   customReport: true,
        // });
        setShowCustomReport(true);
      } else {
        // setHandleExtensionsVisibilty({
        //   ...handleExtensionsVisibilty,
        //   customReport: false,
        // });
        setShowCustomReport(false);
      }
      if (activatedExtension.includes(extensionList.customerApp.id)) {
        setShowCustomerApp(true);
      } else {
        setShowCustomerApp(false);
      }
      if (activatedExtension.includes(extensionList.identityVerification.id)) {
        setShowIdentityVerification(true);
      } else {
        setShowIdentityVerification(false);
      }
      if (activatedExtension.includes(extensionList.agentWallet.id)) {
        setShowAgentWallet(true);
      } else {
        setShowAgentWallet(false);
      }
      if (activatedExtension.includes(extensionList.stripeConnect.id)) {
        setShowStripeHistory(true);
      } else {
        setShowStripeHistory(false);
      }

      if (activatedExtension.includes(extensionList.agentReferral.id)) {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          agentReferral: true,
        });
        tempVisibleExtensionsData.agentReferral = true;
      } else {
        setHandleExtensionsVisibilty({
          ...handleExtensionsVisibilty,
          agentReferral: false,
        });
        tempVisibleExtensionsData.agentReferral = false;
      }

      if (activatedExtension.includes(extensionList.scheduledPayments.id)) {
        tempVisibleExtensionsData.scheduledPayments = true;
      } else {
        tempVisibleExtensionsData.scheduledPayments = false;
      }

      if (activatedExtension.includes(extensionList.broadcast.id)) {
        tempVisibleExtensionsData.broadcast = true;
      } else {
        tempVisibleExtensionsData.broadcast = false;
      }
      setHandleExtensionsVisibilty(tempVisibleExtensionsData);
    } else {
      setShowRucurring(false);
      setShowBookingForm(false);
      setHandleExtensionsVisibilty(handleExtensionsVisibilty);
      setShowAgentReferral(false);
      setShowStripeHistory(false);
      setShowCustomerApp(false);
      setShowIdentityVerification(false);
      setEatHappyNowReport(false);
      setShowAgentReferral(false);
      setShowScheduledPayment(false);
      setCustomerSubscription(false);
      setAgentIncentive(false);
      setCustomerPromo(false);
    }
  };
  const fetchChatConversationStatus = async (id?: any) => {
    let headers = {
      access_token: appData.loginData.access_token,
    };
    let reqData = {
      headers: headers,
    };
    const res: any = await ApiService.get(
      ChatEndpoints.conversationStatus(reqData)
    );
    setUnreadMsg(res?.data?.unread_messages);
  };
  const onJobListClick = () => {
    if (!location.pathname.includes("/jobs/list")) {
      if (window.location.hostname == "app.cupboardwagon.com") {
        history.push("/jobs/list/byreferenceid");
      } else {
        history.push("/jobs/list");
      }
    } else {
      history.push("/temp");
      setTimeout(() => {
        history.goBack();
      });
    }
  };
  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={goToHome}
            edge="start"
            className={clsx(classes.menuButton, classes.fakeMenuButton, {
              [classes.hide]: open,
            })}
          >
            {/* <img style={{ width: "35px" }} src={smallLogo} /> */}
            <img
              // style={{ width: "35px" }}
              className={classes.smallLogo}
              src={
                userIcons && userIcons.favicon ? userIcons.favicon : smallLogo
              }
            />
          </IconButton>
          <Header></Header>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={
          clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }) + " sidebar"
        }
        style={
          appData.loginData.onboard_step && appData.loginData.onboard_step <= 4
            ? { pointerEvents: "none" }
            : {}
        }
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>

            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
          <div>
            <img
              src={
                userIcons && userIcons.inner_logo
                  ? userIcons.inner_logo
                  : largeLogo
              }
              onClick={() => redirect("eded")}
              style={{ cursor: "pointer" }}
              className={classes.largeLogo}
            />
          </div>

          {/* <img src={largeLogo} className={classes.largeLogo} /> */}
          {/* <img
            style={{ position: "absolute", left: "210px" }}
            src={icon_to_close_sidebar}
            onClick={handleDrawerClose}
          /> */}
        </div>

        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <img src={icon_to_open_sidebar} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/dashboard");
              resetHeaderTeam();
            }}
            className={classes.selected}
            button
            key="Dashboard"
            selected={location.pathname.includes("/dashboard")}
          >
            <ListItemIcon>
              {/* <img  
              src={dashboard_sidebar_icon}
              alt="header logo" classes={classes}
            /> */}
              <DashboardOutlinedIcon
                color={"secondary"}
              ></DashboardOutlinedIcon>
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Dashboard")} /> : ""}
          </ListItem>
          <div className={classes.divider}></div>
          {Permission && Permission["Read_Jobs"] ? (
            <ListItem
              onClick={() => {
                onJobListClick();
              }}
              selected={location.pathname.includes("/jobs/")}
              className={classes.selected}
              button
              key="Jobs"
            >
              <ListItemIcon>
                <img src={JobListIcon} />
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Jobs")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          {Permission && Permission["Read_Resources"] ? (
            <ListItem
              onClick={() => {
                history.push("/resources/list");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/resources/")}
              button
              key="Resources"
            >
              <ListItemIcon>
                <PersonOutlineOutlinedIcon
                  color={"secondary"}
                ></PersonOutlineOutlinedIcon>
                {/* <img src={ResourceSidebarIcon} alt="resource logo" /> */}
                {/* <ResourceSidebarIcon></ResourceSidebarIcon> */}
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Resources")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          {Permission && Permission["Read_Customers"] ? (
            <ListItem
              onClick={() => {
                history.push("/customers/list");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/customers/")}
              button
              key="Customers"
            >
              <ListItemIcon>
                <img src={CustomerIcon} />
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Customers")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          <div className={classes.divider}></div>
          {isAdmin || Permission["Read_Analytics"] ? (
            <>
              <ListItem
                onClick={() => {
                  if (!analyticsListOpen) {
                    closeAnotherExpandedItems("analytics");
                  }
                  setAnalyticsListOpen(!analyticsListOpen);
                }}
                className={classes.selected}
                button
                key="Analytics"
              >
                <ListItemIcon>
                  <img src={AnalyticsIcon} />
                  {/* <img src={settings_sidebar_icon} alt="task logo" /> */}
                </ListItemIcon>
                {open ? (
                  <ListItemText primary={t("Sidebar:Analytics")} />
                ) : null}
                {open ? (
                  analyticsListOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              <Collapse
                className={classes.subCategoryList}
                in={analyticsListOpen}
                timeout="auto"
                unmountOnExit
              >
                <List>
                  {showRouteAnalytics ? (
                    <ListItem
                      onClick={() => {
                        history.push("/analytics/route-analytics");
                        resetHeaderTeam();
                      }}
                      className={classes.selected}
                      selected={location.pathname.includes(
                        "/analytics/route-analytics"
                      )}
                      button
                      key="Customer"
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AssessmentOutlinedIcon
                          color={"secondary"}
                        ></AssessmentOutlinedIcon>
                      </ListItemIcon>
                      {open ? (
                        <ListItemText primary={t("Sidebar:Route")} />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ) : null}
                  <ListItem
                    onClick={() => {
                      history.push("/analytics/overview");
                      resetHeaderTeam();
                    }}
                    className={classes.selected}
                    selected={location.pathname.includes("/analytics/overview")}
                    button
                    key="Overview"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <PieChartIcon color={"secondary"}></PieChartIcon>
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Overview")} />
                    ) : (
                      ""
                    )}
                  </ListItem>

                  {showCustomReport ? (
                    <ListItem
                      onClick={() => {
                        history.push("/analytics/report");
                        resetHeaderTeam();
                      }}
                      className={classes.selected}
                      selected={location.pathname.includes("/analytics/report")}
                      button
                      key="roberto-analytics"
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AssessmentIcon color={"secondary"}></AssessmentIcon>
                      </ListItemIcon>
                      {open ? (
                        <ListItemText primary={t("Sidebar:Custom Report")} />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ) : (
                    <></>
                  )}

                  <ListItem
                    onClick={() => {
                      history.push("/analytics/job");
                      resetHeaderTeam();
                    }}
                    className={classes.selected}
                    selected={location.pathname.includes("/analytics/job")}
                    button
                    key="Job"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <img src={JobListIcon} />
                    </ListItemIcon>
                    {open ? <ListItemText primary={t("Sidebar:Jobs")} /> : ""}
                  </ListItem>

                  <ListItem
                    onClick={() => {
                      history.push("/analytics/resource");
                      resetHeaderTeam();
                    }}
                    className={classes.selected}
                    selected={location.pathname.includes("/analytics/resource")}
                    button
                    key="Resources"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <PersonOutlineOutlinedIcon
                        color={"secondary"}
                      ></PersonOutlineOutlinedIcon>
                    </ListItemIcon>
                    {open ? <ListItemText primary={t("Sidebar:Agents")} /> : ""}
                  </ListItem>

                  <ListItem
                    onClick={() => {
                      history.push("/analytics/customer");
                      resetHeaderTeam();
                    }}
                    className={classes.selected}
                    selected={location.pathname.includes("/analytics/customer")}
                    button
                    key="Customer"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <img src={CustomerIcon} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Customers")} />
                    ) : (
                      ""
                    )}
                  </ListItem>

                  {merchantAnalytics ? (
                    <ListItem
                      onClick={() => {
                        history.push("/analytics/merchantAnalytics");
                        resetHeaderTeam();
                      }}
                      className={classes.selected}
                      selected={location.pathname.includes(
                        "/analytics/merchantAnalytics"
                      )}
                      button
                      key="Merchant"
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AssessmentOutlinedIcon
                          color={"secondary"}
                        ></AssessmentOutlinedIcon>
                      </ListItemIcon>
                      {open ? (
                        <ListItemText primary={t("Sidebar:Merchant")} />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ) : null}
                  {/* Trip Analytics */}
                  {tripAnalytics ? (
                    <ListItem
                      onClick={() => {
                        history.push("/analytics/tripAnalytics");
                        resetHeaderTeam();
                      }}
                      className={classes.selected}
                      selected={location.pathname.includes(
                        "/analytics/tripAnalytics"
                      )}
                      button
                      key="Trip"
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AssessmentOutlinedIcon
                          color={"secondary"}
                        ></AssessmentOutlinedIcon>
                      </ListItemIcon>
                      {open ? <ListItemText primary={t("Sidebar:Trip")} /> : ""}
                    </ListItem>
                  ) : null}

                  {eatHappyNowReport ? (
                    <ListItem
                      onClick={() => {
                        history.push("/analytics/eathappynow/report");
                        resetHeaderTeam();
                      }}
                      className={classes.selected}
                      selected={location.pathname.includes(
                        "/analytics/eathappynow/report"
                      )}
                      button
                      key="EatHappyNowReport"
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AssessmentOutlinedIcon
                          color={"secondary"}
                        ></AssessmentOutlinedIcon>
                      </ListItemIcon>
                      {open ? (
                        <ListItemText primary={t("Sidebar:Food Metrics")} />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ) : null}
                </List>
              </Collapse>
            </>
          ) : (
            ""
          )}
          <ListItem
            onClick={() => {
              selectedAndUnreadMsges.current.value = {};
              history.push("/chat");
            }}
            ref={selectedAndUnreadMsges}
            className={classes.selected}
            selected={location.pathname.includes("/chat")}
            button
            key="Chat"
          >
            <ListItemIcon>
              <ChatBubbleOutlineOutlinedIcon
                color={"secondary"}
              ></ChatBubbleOutlineOutlinedIcon>
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Chat")} /> : ""}
            <span>
              {UnreadMsg ? (
                <ErrorIcon
                  color={"secondary"}
                  style={{
                    color: "red",
                    background: "white",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                // <img
                //   src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Antu_vcs-locally-modified-unstaged.svg/1024px-Antu_vcs-locally-modified-unstaged.svg.png"
                //   style={{ width: "30px", height: "30px" }} />
                ""
              )}
            </span>
          </ListItem>
          {/* <ListItem className={classes.selected} button key="Analytics">
            <ListItemIcon>
              <img src={analytics_sidebar_icon} alt="task logo" />
            </ListItemIcon>
            {open ? <ListItemText primary="Analytics" /> : ""}
          </ListItem> */}

          <>
            {Permission &&
            appData.loginData.workflow_type === 1 &&
            workflowType === 1 &&
            Permission["Read_Jobs"] ? (
              <ListItem
                onClick={() => {
                  history.push("/scheduler/");
                }}
                className={classes.selected}
                selected={location.pathname.includes("/scheduler/")}
                button
                key="scheduler"
              >
                <ListItemIcon>
                  <img src={SchedulerIcon} />
                </ListItemIcon>
                {open ? <ListItemText primary={t("Sidebar:Scheduler")} /> : ""}
              </ListItem>
            ) : null}
          </>
          <div className={classes.divider}></div>
          <>
            {isAdmin ||
            (Permission["Read_Recurring"] && showRecurring) ||
            (Permission["Read_Formsettings"] && showBookingForm) ? (
              <ListItem
                onClick={() => {
                  setExtensionsListOpen(!extensionsListOpen);
                  if (!extensionsListOpen) {
                    closeAnotherExpandedItems("extensionsList");
                  }
                }}
                className={classes.selected}
                button
                key="Extensions"
              >
                <ListItemIcon>
                  <ExtensionOutlinedIcon
                    color={"secondary"}
                  ></ExtensionOutlinedIcon>
                </ListItemIcon>
                {open ? <ListItemText primary={t("Sidebar:Extensions")} /> : ""}
                {open ? (
                  extensionsListOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
            ) : (
              ""
            )}

            <Collapse
              className={classes.subCategoryList}
              in={extensionsListOpen}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {isAdmin ? (
                  <ListItem
                    onClick={() => {
                      history.push("/extensions/list");
                    }}
                    className={classes.selected}
                    selected={location.pathname === "/extensions/list"}
                    button
                    key={"Extension1"}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <ListIcon color={"secondary"}></ListIcon>
                    </ListItemIcon>
                    {open ? <ListItemText primary={t("Sidebar:All")} /> : ""}
                  </ListItem>
                ) : (
                  ""
                )}
                {handleExtensionsVisibilty.agentReferral && isAdmin ? (
                  <ListItem
                    onClick={() => {
                      history.push("/extensions/agentreferral");
                    }}
                    className={classes.selected}
                    selected={location.pathname === "/extensions/agentreferral"}
                    button
                    key={"Extension-referral"}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <img src={AgentReferral} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar: Agent Referral")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}

                {showScheduledPayment ? (
                  <ListItem
                    onClick={() => {
                      history.push("/extensions/payments/rules");
                    }}
                    className={classes.selected}
                    selected={
                      location.pathname === "/extensions/payments/rules"
                    }
                    button
                    key={"scheduled-payments"}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <PaymentIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar: Payments")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}
                {/* deepak */}
                {customerSubscription && isAdmin ? (
                  <ListItem
                    onClick={() => {
                      history.push("/extensions/subscription/plans");
                    }}
                    className={classes.selected}
                    selected={
                      location.pathname === "/extensions/subscription/plans"
                    }
                    button
                    key={"scheduled-payments"}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <PaymentIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Subscription")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}

                {/* Agent incentives */}
                {agentIncentive && isAdmin ? (
                  <ListItem
                    onClick={() => {
                      history.push("/extensions/agentIncentive/rules");
                    }}
                    className={classes.selected}
                    selected={
                      location.pathname === "/extensions/agentIncentive/rules"
                    }
                    button
                    key={"scheduled-payments"}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <PaymentIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Agent Incentives")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}

                {(isAdmin && showBookingForm) ||
                (Permission["Read_Formsettings"] && showBookingForm) ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname.includes(
                      "/settings/bookingform"
                    )}
                    onClick={() => {
                      history.push("/settings/bookingform");
                    }}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <BusinessCenterIcon color={"secondary"} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Booking Forms")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}
                {isAdmin && customerPromo ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname.includes("/extensions/promos")}
                    onClick={() => {
                      history.push("/extensions/promos");
                    }}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <BusinessCenterIcon color={"secondary"} />
                    </ListItemIcon>
                    {open ? <ListItemText primary={t("Sidebar:Promos")} /> : ""}
                  </ListItem>
                ) : (
                  ""
                )}
                {isAdmin && showIdentityVerification ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname.includes(
                      "/settings/verificationReport"
                    )}
                    onClick={() => {
                      history.push("/settings/verificationReport");
                    }}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <Face color={"secondary"} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Face Verification")} />
                    ) : (
                      ""
                    )}{" "}
                  </ListItem>
                ) : (
                  ""
                )}
                {isAdmin && showAgentWallet ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname.includes(
                      "/extensions/agentwallet/report"
                    )}
                    onClick={() => {
                      history.push("/extensions/agentwallet/report");
                    }}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <img
                        style={{ height: "20px" }}
                        src={walleticon}
                        alt="team drpdown"
                      ></img>
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Agent Wallet")} />
                    ) : (
                      ""
                    )}{" "}
                  </ListItem>
                ) : (
                  ""
                )}
                {isAdmin && showStripeHistory ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={
                      location.pathname === "/extensions/stripe/history"
                    }
                    onClick={() => {
                      history.push("/extensions/stripe/history");
                    }}
                    //  key="Stripe History"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <ReceiptIcon color={"secondary"} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Common:Stripe History")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}
                {showRecurring ? (
                  Permission && Permission["Read_Recurring"] ? (
                    <ListItem
                      className={classes.selected}
                      button
                      selected={location.pathname.includes(
                        "/settings/recurring"
                      )}
                      onClick={() => {
                        history.push("/settings/recurring");
                      }}
                    >
                      <ListItemIcon className={classes.subCategoryIcon}>
                        <AutorenewIcon color={"secondary"}></AutorenewIcon>
                        {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                      </ListItemIcon>
                      {open ? (
                        <ListItemText primary={t("Sidebar:Recurring Rules")} />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {handleExtensionsVisibilty.broadcast ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname === "/extensions/broadcast"}
                    onClick={() => {
                      history.push("/extensions/broadcast");
                    }}
                    //  key="Stripe History"
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <img src={BroadCastIcon} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Broadcast")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
            </Collapse>
          </>
          {Permission &&
          (Permission["Read_Teams"] ||
            Permission["Read_Supervisors"] ||
            Permission["Read_Autoallocation"] ||
            Permission["Read_Geofence"] ||
            Permission["Read_Templates"] ||
            Permission["Read_Roles"]) ? (
            <ListItem
              onClick={() => {
                setSettingsListOpen(!settingsListOpen);
                if (!settingsListOpen) {
                  closeAnotherExpandedItems("settingsList");
                }
              }}
              className={classes.selected}
              button
              key={"Setting1"}
            >
              <ListItemIcon>
                <SettingsOutlinedIcon
                  color={"secondary"}
                ></SettingsOutlinedIcon>
                {/* <img src={settings_sidebar_icon} alt="task logo" /> */}
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Settings")} /> : null}
              {open ? settingsListOpen ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
          ) : (
            ""
          )}
          <Collapse
            className={classes.subCategoryList}
            in={settingsListOpen}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {appData.loginData.user_id === appData.loginData.admin_id ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/general")}
                  onClick={() => {
                    history.push("/settings/general");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <SettingsIcon color={"secondary"}></SettingsIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:General")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Teams"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/teams")}
                  onClick={() => {
                    history.push("/settings/teams");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <GroupOutlinedIcon color={"secondary"}></GroupOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Teams")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Supervisors"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/supervisors")}
                  onClick={() => {
                    history.push("/settings/supervisors");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <SupervisedUserCircleOutlinedIcon
                      color={"secondary"}
                    ></SupervisedUserCircleOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Supervisors")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Geofence"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/geofence")}
                  onClick={() => {
                    history.push("/settings/geofence");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <MapIcon color={"secondary"}></MapIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Geofences")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Templates"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/templates")}
                  onClick={() => {
                    history.push("/settings/templates");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <TextFieldsOutlinedIcon
                      color={"secondary"}
                    ></TextFieldsOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Templates")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={
                    location.pathname.includes("/settings/notifications") &&
                    !location.pathname.includes("webhookLogs")
                  }
                  onClick={() => {
                    history.push("/settings/notifications");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <NotificationsIcon color={"secondary"}></NotificationsIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Notifications")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}

              {Permission && Permission["Read_Autoallocation"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/autoallocation"
                  )}
                  onClick={() => {
                    history.push("/settings/autoallocation");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <img src={AutoAllocationIcon} alt="Auto Allocation"></img>
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Auto Allocation")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/language/changelanguage"
                  )}
                  onClick={() => {
                    history.push("/settings/language/changelanguage");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <LanguageIcon color={"secondary"}></LanguageIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Language Manager")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Roles"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={
                    location.pathname.includes("/settings/roles") ||
                    location.pathname.includes("/settings/role")
                  }
                  onClick={() => {
                    history.push("/settings/roles");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <img src={RolesIcon} alt="Roles"></img>
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Roles")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/resourceapp")}
                  onClick={() => {
                    history.push("/settings/resourceapp");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <PhoneIphoneIcon color={"secondary"}></PhoneIphoneIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Agent App")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Availability"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/resourceschedule"
                  )}
                  onClick={() => {
                    history.push("/settings/resourceschedule");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <ScheduleOutlinedIcon
                      color={"secondary"}
                    ></ScheduleOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Agent Schedule")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin && showCustomerApp ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/customerapp")}
                  onClick={() => {
                    history.push("/settings/customerapp");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <PhoneIphoneOutlinedIcon color={"secondary"} />
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Customer App")} />
                  ) : (
                    ""
                  )}{" "}
                </ListItem>
              ) : (
                ""
              )}

              {isAdmin && showCustomerApp && workflowType === 1 ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/catalog")}
                  onClick={() => {
                    history.push("/settings/catalog");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <PhoneIphoneIcon color={"secondary"}></PhoneIphoneIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:N-Level Catalog")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Enprules"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={
                    location.pathname.includes("/settings/enp/") ||
                    location.pathname.includes("settings/earning") ||
                    location.pathname.includes("settings/pricing")
                  }
                  onClick={() => {
                    // enpType === 0
                    //   ? history.push("/settings/pricing")
                    //   : history.push("/settings/earning");
                    history.push("/settings/enp/earning");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <AttachMoneyIcon color={"secondary"} />
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Earning & Pricing")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}

              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/tagsmanager")}
                  onClick={() => {
                    history.push("/settings/tagsmanager");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    {" "}
                    <img src={TagsManagerIcon} />
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Tags Manager")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {appData.loginData.user_id === appData.loginData.admin_id &&
              !arrayOfLocation.includes(window.location.host) ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/billing")}
                  onClick={() => {
                    history.push("/settings/billing");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <CreditCardOutlinedIcon color={"secondary"} />
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Billing")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/apikey")}
                  onClick={() => {
                    history.push("/settings/apikey");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <VpnKeyIcon color={"secondary"}></VpnKeyIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:API Keys")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
            </List>
          </Collapse>
          {!(appData && appData.loginData.domain) ? (
            <ListItem
              onClick={() => {
                window.location.href =
                  "http://networkon.helpdocsonline.com/home";
              }}
              className={classes.selected}
              button
              key="Settings"
            >
              <ListItemIcon>
                <img src={HelpCenterIcon} />
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Help Center")} /> : ""}
            </ListItem>
          ) : null}
          {isAdmin ? (
            <ListItem
              className={classes.selected}
              button
              selected={location.pathname.includes("webhookLogs")}
              onClick={() => {
                history.push("/settings/notifications/webhookLogs");
              }}
            >
              <ListItemIcon>
                <HistoryOutlinedIcon color={"secondary"}></HistoryOutlinedIcon>
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:System Logs")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
        </List>
      </Drawer>
    </Fragment>
  );
};
export default HeaderSidebarWrapper;

// {TabPermission && TabPermission[RolePermissions.Read_APIKey] ? (
//   <ListItem
//   className={classes.selected}
//   button
//   selected={location.pathname.includes("/settings/apikey")}
//   onClick={() => {
//     history.push("/settings/apikey");
//   }}
// >
//   <ListItemIcon>
//     <VpnKeyIcon
//       color={"secondary"}
//     ></VpnKeyIcon>
//     {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
//   </ListItemIcon>
//   {open ? <ListItemText primary="API Key" /> : ""}
// </ListItem>
// ): ( "" )}
